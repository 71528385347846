import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { BsSearch } from 'react-icons/bs';
import ModalSelectItem from './ModalSelectItem';
import SelectedItems from './SelectedItems';

const CardCombination = props => {
  const { setValue, discountTypesWatch, control } = props;
  const [toggleAll, setToggleAll] = useState(false);
  const [toggleProduct, setToggleProduct] = useState(false);
  const [toggleShipping, setToggleShipping] = useState(false);
  const [toggleOrder, setToggleOrder] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [discountType, setDiscountType] = useState();

  const wcombinedWatch = useWatch({ control, name: 'wcombined' });
  const itemType = 'campaign';

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  useEffect(() => {
    if (!wcombinedWatch || !Array.isArray(wcombinedWatch)) return;
    const temp_arr = [];
    wcombinedWatch.map(item => {
      if (item?.discount_type === 1) {
        setToggleProduct(true);
      } else if (item?.discount_type === 2) {
        setToggleOrder(true);
      } else if (item?.discount_type === 4) {
        setToggleShipping(true);
      } else if (item === 'all') {
        setToggleAll(true);
      }
      if (item?.name === 'all') {
        temp_arr.push({
          ...item,
          id: `all ${
            discountType === 1
              ? 'product'
              : discountType === 2
              ? 'order'
              : 'shipping'
          }`
        });
      } else {
        temp_arr.push(item);
      }
    });
    setSelectedItems(temp_arr);
  }, [wcombinedWatch]);

  useEffect(() => {
    if (toggleProduct || toggleShipping || toggleOrder) {
      setValue('is_combined', true);
    } else {
      setValue('is_combined', false);
    }
  }, [toggleProduct, toggleShipping, toggleOrder]);

  useEffect(() => {
    if (toggleAll) {
      setValue('wcombined', 'all');
    } else {
      const temp_arr = selectedItems.map(e => e.id);
      setValue('wcombined', temp_arr.toString());
    }
  }, [selectedItems, toggleAll]);

  const handelInputChange = (event, index) => {
    setDiscountType(index);

    openModal();
    setSearchValue(event.target.value);
  };

  const modalProps = {
    searchValue,
    setSearchValue,
    showModal,
    closeModal,
    selectedItems,
    setSelectedItems,
    itemType,
    discountType
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Combinations</Card.Title>
        <Form.Group className="mb-1 me-2" controlId="allCombine">
          <Form.Check type="checkbox">
            <Form.Check.Input
              type="checkbox"
              checked={toggleAll}
              onChange={() => setToggleAll(!toggleAll)}
            />
            <Form.Check.Label className="m-0">All</Form.Check.Label>
          </Form.Check>
        </Form.Group>
        {!toggleAll && (
          <>
            <Flex>
              {discountTypesWatch !== 2 && (
                <Form.Group className="mb-1 me-2" controlId="toggleProduct">
                  <Form.Check type="checkbox">
                    <Form.Check.Input
                      type="checkbox"
                      checked={toggleProduct}
                      onChange={() => setToggleProduct(!toggleProduct)}
                    />
                    <Form.Check.Label className="m-0">
                      Other product campaigns
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
              )}
              {toggleProduct && (
                <Form.Group className="flex-fill" controlId="search-open-modal">
                  <InputGroup>
                    <InputGroup.Text>
                      <BsSearch />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="search-open-modal"
                      placeholder={'Search Campaigns'}
                      value={''}
                      onChange={e => handelInputChange(e, 1)}
                    />
                    <Button
                      variant="outline-primary"
                      onClick={e => handelInputChange(e, 1)}
                    >
                      Browse
                    </Button>
                  </InputGroup>
                </Form.Group>
              )}
            </Flex>
            {toggleProduct && (
              <Form.Group className="mb-1" controlId="txtProduct">
                <Form.Text>
                  No product campaigns are currently set to combine. To let
                  customers use more than one campaign, set up at least one
                  product campaign that combines with product campaigns.
                </Form.Text>
              </Form.Group>
            )}
            {discountTypesWatch !== 4 && (
              <Flex>
                <Form.Group className="mb-1 me-2" controlId="toggleShipping">
                  <Form.Check type="checkbox">
                    <Form.Check.Input
                      type="checkbox"
                      checked={toggleShipping}
                      onChange={() => setToggleShipping(!toggleShipping)}
                    />
                    <Form.Check.Label className="m-0">
                      Shipping campaigns
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
                {toggleShipping && (
                  <Form.Group
                    className="flex-fill"
                    controlId="search-open-modal"
                  >
                    <InputGroup>
                      <InputGroup.Text>
                        <BsSearch />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="search-open-modal"
                        placeholder={'Search Campaigns'}
                        value={''}
                        onChange={e => handelInputChange(e, 4)}
                      />
                      <Button
                        variant="outline-primary"
                        onClick={e => handelInputChange(e, 4)}
                      >
                        Browse
                      </Button>
                    </InputGroup>
                  </Form.Group>
                )}
              </Flex>
            )}
            {toggleShipping && (
              <Form.Group className="mb-1" controlId="txtShipping">
                <Form.Text>
                  No shipping campaigns are currently set to combine. To let
                  customers use more than one campaign, set up at least one
                  shipping campaign that combines with product campaigns.
                </Form.Text>
              </Form.Group>
            )}
            {discountTypesWatch === 4 && (
              <Flex>
                <Form.Group className="mb-1 me-2" controlId="toggleOrder">
                  <Form.Check type="checkbox">
                    <Form.Check.Input
                      type="checkbox"
                      defaultChecked={true}
                      onChange={() => setToggleOrder(!toggleOrder)}
                    />
                    <Form.Check.Label className="m-0">
                      Order campaigns
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
                {toggleOrder && (
                  <Form.Group
                    className="flex-fill"
                    controlId="search-open-modal"
                  >
                    <InputGroup>
                      <InputGroup.Text>
                        <BsSearch />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="search-open-modal"
                        placeholder={'Search Campaigns'}
                        value={''}
                        onChange={e => handelInputChange(e, 2)}
                      />
                      <Button
                        variant="outline-primary"
                        onClick={e => handelInputChange(e, 2)}
                      >
                        Browse
                      </Button>
                    </InputGroup>
                  </Form.Group>
                )}
              </Flex>
            )}
            {toggleOrder && (
              <Form.Group className="mb-1" controlId="txtOrder">
                <Form.Text>
                  No order campaigns are currently set to combine. To let
                  customers use more than one campaign, set up at least one
                  order campaign that combines with order campaigns.
                </Form.Text>
              </Form.Group>
            )}
          </>
        )}
        {!toggleAll && (toggleProduct || toggleOrder || toggleShipping) && (
          <Row style={{ maxHeight: 400, overflow: 'auto' }}>
            <Col xs={12}>
              <SelectedItems
                items={selectedItems}
                setItems={setSelectedItems}
                itemType={itemType}
              />
            </Col>
          </Row>
        )}
      </Card.Body>
      <ModalSelectItem {...modalProps} />
    </Card>
  );
};

CardCombination.propTypes = {
  setValue: PropTypes.func,
  control: PropTypes.object,
  handleChange: PropTypes.func,
  discountTypesWatch: PropTypes.number
};

export default CardCombination;
